export default () => {
    // adding accessibility labels to owl carousel navigation buttons
    $(window).on('load', function () {
        const $owlNaviagtion = $('.owl-nav:not(.disabled)');

        if ($owlNaviagtion)
            $owlNaviagtion
                .find('.owl-prev').attr('aria-label', 'Previous Slide')
                .next().attr('aria-label', 'Next Slide');
    });

    // creating carousels
    createCarousel('.carousel-homepage', {
        animateOut: 'fadeOut',
        autoplayTimeout: 6000,
        responsive: {
            1200: {
                nav: true,
                navText: ['<i class="fas fa-3x fa-chevron-left text-white"></i>', '<i class="fas fa-3x fa-chevron-right text-white"></i>']
            }
        }
    });

    createCarousel('.carousel-services', {
        autoplayTimeout: 4000,
        responsive: {
            0: {
                items: 1,
                margin: 30,
                stagePadding: 20
            },
            576: {
                items: 1,
                margin: 100,
                stagePadding: 120
            },
            768: {
                items: 2,
                margin: 80,
                stagePadding: 100
            },
            1200: {
                items: 3,
                margin: 80,
                stagePadding: 100
            }
        }
    });

    createCarousel('.carousel-welcome', {
        animateOut: 'fadeOut'
    });

    createCarousel('.carousel-care', {
        animateOut: 'fadeOut'
    });

    createCarousel('.carousel-page-header', {
        animateOut: 'fadeOut',
        autoplayTimeout: 8000
    });

    createCarousel('.carousel-team', {
        margin: 30,
        nav: true,
        navText: ['<i class="fas fa-2x fa-chevron-left text-dark"></i>', '<i class="fas fa-2x fa-chevron-right text-dark"></i>'],
        responsive: {
            0: {items: 1},
            768: {items: 2},
            992: {items: 3},
            1200: {items: 4}
        }
    });

    createCarousel('.carousel-news', {
        animateOut: 'fadeOut',
        autoplayTimeout: 8000
    });

    createCarousel('.carousel-blog', {
        animateOut: 'fadeOut',
        autoplayTimeout: 10000
    });

    createCarousel('.carousel-testimonials', {
        autoplayTimeout: 7000,
        nav: true,
        navText: ['<i class="fas fa-2x fa-chevron-left text-dark"></i>', '<i class="fas fa-2x fa-chevron-right text-dark"></i>'],
    });

    createCarousel('.carousel-partners', {
        margin: 30,
        responsive: {
            0: {items: 2},
            400: {items: 3},
            768: {items: 4},
            992: {items: 6}
        },
    });

    // creating carousel from template
    function createCarousel(selector, additionalOptions) {
        // carousel options
        let options = {
            items: 1,
            rewind: true,
            autoplay: true,
            dots: false
        };

        // adding additional options if those are exist
        if (additionalOptions)
            options = Object.assign(additionalOptions, options);

        return $(selector).owlCarousel(options);
    }
};
