export default () => {
  const groupSelect = document.querySelector("#pricing-group-select");

  if (!groupSelect) return;

  groupSelect.addEventListener("change", (event) => {
    const targetGroupId = event.target.value;
    const isShowAllOption = targetGroupId === "all";

    document
      .querySelectorAll(".pricing__group-container")
      .forEach((container) => {
        if (isShowAllOption) {
          container.classList.remove("d-none");

          return;
        }

        if (container.id === targetGroupId) {
          container.classList.remove("d-none");

          return;
        }

        container.classList.add("d-none");
      });
  });
};
